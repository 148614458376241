.recordTitle {
    color: #fff;
    margin-right: 0.6rem;
}

.entityTitle {
    color: #00000099;

}

.createButtonWrapper {
    border: 0.06rem solid #0085E2;
    line-height: 1.5 !important;
}

.listActionSpeedDial {
    position: fixed;
    bottom: 3.75rem;
    right: 3.75rem;
}

.reportIframeWrapper {
    height: 80vh;
}

.reportIframe {
    width: 85vw;
    height: 100%;
}

.sideBarFilter {
    min-width: 13.12rem !important;
}

.cardFilter {
    order: -1;
    flex: 0 0 15rem;
    margin: 1.66rem 0;
}

.rightButton {
    display: flex;
}

.rightButton button {
    margin-left: auto;
}

.clearPadding {
    padding: 0;
}

.postBodyButton {
    display: flex;
    justify-content: flex-start;
}

.postBodyButton button {
    margin-bottom: 0.6rem;
    margin-right: 0.75rem;
}

td:has(span.bgColorRed, span.bgColorOrange, span.bgColorBlue, span.bgColorGreen, span.bgColorPink) {
    text-align: center;
}

td:has(span.bgColorRed) {
    background: indianred;
    color: white;
}

td:has(span.bgColorOrange) {
    background: darkorange;
}

td:has(span.bgColorBlue) {
    background: lightblue;
}

td:has(span.bgColorGreen) {
    background: lightgreen;
}

td:has(span.bgColorPink) {
    background: hotpink;
}

td:has(span.bgColorYellow) {
    background: gold;
}

.logo {
    max-width: 150px;
    margin-left: -35px;
}

.pointer {
    cursor: pointer;
}

.contentWrapper > p {
    margin-left: 0 !important;
}

.FilterFontSize {
    font-size: 14px !important;
}