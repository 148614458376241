body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-sizeSmall.MuiButton--without-label {
    min-width: 25px;
}

.MuiButton-sizeSmall.MuiButton--without-label span {
    margin-right: 0;
}

a.edit-button-list {
    font-size: 0;
}

a.edit-button-list span {
    font-size: 16px;
}

.MuiTablePagination-spacer {
    -webkit-flex: 0 0 0 !important;
    -ms-flex: 0 0 0 !important;
    flex: 0 0 0 !important;
}
